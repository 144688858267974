<template>
    <div>
        <div v-if="!empty">
            <div class="d-flex justify-content-between mb-4">
                <div>
                    <b-form inline @submit.stop.prevent="onSearch">
                        <b-input-group size="md">
                            <b-form-input type="search" v-model="search" @keyup="onSearch" @click="onSearch" :placeholder="$t('generally.filter') + '...'"></b-form-input>
                            <b-input-group-append>
                                <b-button variant="orange">
                                    <b-icon-search></b-icon-search>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form>
                </div>
                <div>
                    <b-button size="md" variant="orange" v-b-modal.redeem-modal>{{ $t('pages.myResources.buttons.redeemModal') }}</b-button>
                </div>
            </div>

            <b-card-group deck >
                <resource-card
                    v-for="resource in filteredResourcesList" :key="resource.resourceId" 
                    :resourceId="resource.resourceId" 
                    :data="resource"
                    class="pt-4"
                />
            </b-card-group>
            <div v-show="loading" class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
            </div>
        </div>

        <!--Empty-->
        <div v-else class="d-flex justify-content-center align-items-center" >
            <CCard>
                <CCardBody>
                    <div class="text-center mb-2">
                        <b-icon-emoji-sunglasses font-scale="6"/>
                    </div>
                    <h4>{{ $t('pages.myResources.redeem.emptyResources.title') }}</h4>
                    <h6>{{ $t('pages.myResources.redeem.emptyResources.subtitle') }}</h6>
                    <b-form>
                        <b-form-group>
                            <b-form-input type="text" v-model="key" :placeholder="$t('pages.myResources.form.registryKey')"></b-form-input>
                        </b-form-group>
                        <div class="d-flex justify-content-end">
                            <b-button variant="orange" @click="onRedeemKey">
                                <font-awesome-icon icon="plus"/>
                                {{ $t('buttons.add') }}
                            </b-button>
                        </div>
                    </b-form>
                </CCardBody>
            </CCard>
        </div>


        <b-modal
            id="redeem-modal"
            :title="$t('pages.myResources.headers.redeem')"
            :ok-title="$t('buttons.add')"
            ok-variant="orange"
            :cancel-title="$t('buttons.cancel')"
            @ok.prevent="onRedeemKey"
            centered
        >
            <b-form-group
                :label="$t('pages.myResources.redeem.emptyResources.subtitle')"
            >
                <b-form-input type="text" v-model="key" :placeholder="$t('pages.myResources.form.registryKey')"></b-form-input>
            </b-form-group>
        </b-modal>


    </div>
</template>

<script>
import ResourceCard from "../components/myResources/resource-card";

import keyService from "../services/keyService";
import resourceService from "../services/resourceService";
import { EventBus } from "../services/EventBus";


export default {
    name: "MyResources",
    data: () => ({
        myResources: {},
        filteredResourcesList: [],
        loading: true,
        search: '',
        key: '',
        empty: false,
        eventBus: null
    }),
    watch: {
        search(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.onSearch();
            }
        }
    },
    components: {
        ResourceCard
    },
    mounted() {
        this.loadAll();
       
        // adding EventBus listener
        setTimeout(() => {
            this.eventBus = EventBus.$on('sync', () => {
                this.loadAll();
            });
        }, 5000)

    },
    computed: {
        myResourcesList() {
            return Object.values(this.myResources).sort((a, b) => {
                let fa = (a.name[this.locale] ? a.name[this.locale] : a.name).toLowerCase(),
                    fb = (b.name[this.locale] ? b.name[this.locale] : b.name).toLowerCase()

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
        }
    },
    methods: {
        onSearch() {
            this.filteredResourcesList = this.myResourcesList.filter(item => JSON.stringify(Object.values(item)).toLowerCase().match(this.search.toLowerCase()));
        },
        loadAll() {
            resourceService.get(this.$store.getters["user/id"]).then(response => {

                for (const resourceId of Object.keys(response.data)) {
                    if( !this.myResources[resourceId] || 
                        (JSON.stringify(response.data[resourceId]) != JSON.stringify(this.myResources[resourceId])) ) {
                        this.myResources[resourceId] = response.data[resourceId];
                    }
                }
                this.filteredResourcesList = this.myResourcesList;
                console.log(this.filteredResourcesList);
                
                this.empty = this.myResourcesList.length === 0;
                this.loading = false;
            })
            .catch( () => {
                this.loading = false;
            });
        },

        onRedeemKey() {
            if (this.key === '') {
                this.$toastr.e(this.$t('pages.myResources.messages.error.keyEmpty'));
                return;
            }
            if (!this.key.match(/^[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}$/i)) {
                this.$toastr.e(this.$t('pages.myResources.messages.error.wrongFormat'));
                return;
            }
            keyService.redeem(this.key.toLowerCase()).then(() => {
                this.$toastr.s(this.$t('pages.myResources.messages.success.keyRedeem'));
                this.key = '';
                this.loadAll();
                this.$bvModal.hide('redeem-modal');
            }).catch(error => {
                this.errorHandler(error);
            });
        }
    },
    beforeDestroy() {
        // removing EventBus listener
        EventBus.$off('sync', this.eventBus);
    }
};
</script>

<style scoped>
</style>