<template>
    <div>
        <b-overlay :show="loading" opacity="0.92" >
            <CCard style="width: 480px">
                <CCardHeader class="d-flex justify-content-between">
                    <div>
                        <b-icon :icon="resourceIcons[resourceType]" /> {{ $t(`generally.resource.${resourceType}`) }} <slot name="headerTypeInfo"/>
                    </div>
                    <div>
                        <slot name="headerStatusInfo"/>
                    </div>
                </CCardHeader>
                <CCardBody  class="d-flex flex-column">
                    <CCardTitle><slot name="title"/></CCardTitle> 
                    <b-row  class="text-black-50 width-100 mt-1 ml-1">
                        <b-col v-if="showIcon" cols="auto" class="m-0 p-0">
                            <slot name="icon"/>
                        </b-col>
                        <b-col v-else cols="auto" class="m-0 p-0">
                            <slot name="skeleton">
                                <b-skeleton width="35%" class="mt-3"></b-skeleton>
                                <b-skeleton width="50%" class="mt-1"></b-skeleton>
                                <b-skeleton width="75%" class="mt-1"></b-skeleton>
                            </slot>
                        </b-col>
                        <b-col class="ml-4 p-0">
                            <slot name="description"/>
                        </b-col>
                    </b-row>

                </CCardBody>

                <CCardFooter v-if="showFooter" class="d-flex justify-content-between">
                     <slot name="footer"/>
                </CCardFooter>
                
            </CCard>
            <template #overlay>
                <div class="text-center">
                    <span class="spinner-border"></span>
                    <p id="cancel-label">{{ loadingText }}</p>
                </div>
            </template>
        </b-overlay>
    </div>
</template>

<script>

export default {
    name: "resource-card-outline",
    components: { },
    props: {
        resourceType: { type: String },
        loading: { type: Boolean, default: false },
        loadingText: { type: String },
        showIcon: { type: Boolean, default: true },
        showFooter: { type: Boolean, default: true },
        locale: { type: String },
    },
    data: () => ({
        resourceIcons: {
            "account": "lock-fill",
            "app": "window",
            "download": "download",
            "link": "link45deg",
            "pool": "grid3x3-gap-fill",
            "rdsh": "display",
            "faq": "book"
        }
    })
};
</script>
