<template>
    <div>

        <card-outline 
            resourceType="download"
            :loading="loading"
            :loadingText="loadingText"
            :locale="locale"
        >
            <template #title>
                {{ name[locale] }}
            </template>

            <template #icon>
                <img :src="data.image" height="64" v-show="picture" @load="picture = true" @error="picture = false">
                <b-icon font-scale="4" icon="file-earmark-arrow-down" id="download-btn" v-show="!picture" variant="orange"></b-icon>
            </template>

            <template #description>
                {{ description[locale] }}
            </template>

            <template #footer>
                <div>
                    <b-button size="sm mr-2" :title="$t('buttons.download')" variant="orange" @click="$bvModal.show('detail-download-modal-' + data.id)">
                        <font-awesome-icon icon="play"/>
                        {{ $t('buttons.download') }}
                    </b-button>
                </div>
            </template>
        </card-outline>

        <b-modal
            :id="'detail-download-modal-' + data.id"
            size="lg"
            ok-only
            ok-variant="orange"
            :ok-title="$t('buttons.close')"
        >
            <template #modal-header="{ hide }">
                <div>
                    <h5 class="modal-title d-inline-block">Download: {{ name[locale] }}</h5>
                    <b-badge variant="orange" class="ml-2" v-if="data.users_downloads">{{ $t('pages.myResources.expiresAt') }}: {{ $moment(data.users_downloads.expiresAt).format('DD.MM.YYYY H:mm') }}</b-badge>
                </div>
                <button type="button" @click.prevent="hide" class="close">×</button>
            </template>
            {{ description[locale] }}
            <hr>
            <b-alert variant="danger" dismissible fade :show="browserAlert && data.type === 'one'">
                {{ $t('pages.myResources.resource.downloads.messages.detectionIssue') }}
            </b-alert>
            <b-row v-if="os !== ''" class="pb-3">
                <b-col md="4">
                    {{ $t('pages.myResources.resource.downloads.detectedOs') }}:
                </b-col>
                <b-col md="8">
                    {{ os.name }} {{ os.version }} {{ os.architecture }}
                </b-col>
            </b-row>
            <b-form-group
                label-cols-md="4"
                :label="$t('pages.myResources.resource.downloads.selectedOs') + ':'"
                v-if="data.downloadType === 'one'"
            >
                <b-form-select
                    v-model="selectedFile"
                    :options="selectOptions"
                    value-field="file"
                    text-field="label"
                ></b-form-select>
            </b-form-group>
            <div v-else>
                <b-table
                    small
                    striped
                    :empty-text="$t('generally.tables.emptyText')"
                    :empty-filtered-text="$t('generally.tables.emptyFilteredText')"
                    show-empty
                    :items="files"
                    :fields="fields"
                >
                    <template #cell(fileIcon)="row">
                        <font-awesome-icon :icon="getIcon(row.item.name)" style="font-size: 20px"/>
                    </template>
                    <template #cell(name)="row">
                        {{ row.item.name }}
                    </template>

                    <template #cell(size)="row">
                        {{ bytesToSize(row.item.size) }}
                    </template>
                    <template #cell(action)="row">
                        <b-button size="sm" @click="startDownload(row.item)"><font-awesome-icon icon="download"/>
                            {{ $t('buttons.download') }}</b-button>
                    </template>
                </b-table>
            </div>

            <template #modal-footer="{ ok, cancel, hide }" class="d-flex justify-content-between">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button size="sm" variant="secondary" @click="hide('forget')">
                    {{ $t('buttons.close') }}
                </b-button>
                <div>
                    <b-button size="sm" :title="$t('buttons.open')" v-show="data.downloadType === 'one'" :disabled="selectedFile === ''" variant="orange" @click="startDownload(null)">
                        <font-awesome-icon icon="download"/>
                        {{ $t('buttons.download') }}
                    </b-button>
                </div>
            </template>
        </b-modal>

    </div>
</template>

<script>

import CardOutline from "./resource-card-outline.vue";
import mainServices from "../../services/mainServices";
import {getClassNameForFilename} from "../../services/font-awesome-filetypes";
import fileService from "../../services/fileService";
import downloadService from "../../services/downloadService";

export default {
    name: "download-card",
    components: { CardOutline },
    props: ['data'],
    data: () => ({
        loading: false,
        info: false,
        os: '',
        browser: '',
        locale: 'de',
        update: null,
        loadingText: '',
        selectOptions: [],
        selectedFile: '',
        fields: [],
        name: {
            de: '',
            en: ''
        },
        description: {
            de: '',
            en: ''
        },
        picture: false,
        event: null,
        browserAlert: false,
    }),
    computed: {
        files(){
            let data = this.data.files.map(file => {
                file = JSON.parse(file)
                let labelFind = JSON.parse(this.data.labels).find(label => label.file === file.name);
                file.label = labelFind ? labelFind.label : '';
                return file;
            })
            return data;
        }
    },
    watch: {
        '$i18n.locale': function(newVal) {
            this.locale = newVal;
        }
    },
    mounted() {
        this.fields = [
            { key: "fileIcon", label: '', tdClass: 'align-middle text-center'},
            { key: "name", label: this.$t('pages.content.downloadManagement.form.files.table.name'), tdClass: 'align-middle'},
            { key: "size", label: this.$t('pages.content.downloadManagement.form.files.table.size'), tdClass: 'align-middle'},
            { key: "label", label: this.$t('pages.content.downloadManagement.form.files.table.label'), tdClass: 'align-middle'},
            { key: "action", label: '', tdClass: 'align-middle text-right'},
        ];
        this.browser = mainServices.getBrowser();
        this.updateLocale();
        this.loadingText = this.$t("pages.myResources.loadingMessage", {state: this.$t("states.start")})
        this.getOS();
        this.parse();
        this.checkBrowserCompatibility();
    },
    methods: {
        parse(){
            this.name = JSON.parse(this.data.name);
            this.description = JSON.parse(this.data.description);
        },
        startDownload(file){
            if(file === null){
                file = this.files.find(f => f.name === this.selectedFile);
            }
            downloadService.getDownload(this.data.id, file['sha3-256']).then(response => {
                mainServices.downloadLink(mainServices.apiUrl() + `/downloadLinks/${response.data.data}`, file.name);
            }).catch(error => {
                this.errorHandler(error);
            });
        },
        checkBrowserCompatibility(){
            const validBrowser = {
                chrome: 95,
                edge: 94,
                opera: -1
            }

            for (const validBrowserKey in validBrowser) {
                if(this.browser.name.match(validBrowserKey) && (this.browser.majorVersion >= validBrowser[validBrowserKey] || this.browser.majorVersion === -1)){
                    return false;
                }
            }
            return true;
        },
        updateLocale(){
            this.locale = mainServices.getLocale();
        },
        preSelect(){
            let regexList = {
                full: new RegExp(this.os.name + ' ' + this.os.version + ' ' + this.os.architecture, 'i'),
                architecture: new RegExp(this.os.name + ' ' + this.os.architecture, 'i'),
                version: new RegExp(this.os.name + ' ' + this.os.version, 'i'),
                simple:  new RegExp(this.os.name, 'i'),
                type:  new RegExp(this.os.type, 'i')
            }
            const labels = JSON.parse(this.data.labels);
            this.selectOptions = labels;
            let found;

            for (const regex in regexList) {
                found = labels.find(label => label.label.match(regexList[regex]))
                if (found) break;
            }
            if(found)
                this.selectedFile = found.file;
        },
        getOS(){
            this.os = mainServices.getOS();
            if (navigator.userAgentData) {
                navigator.userAgentData.getHighEntropyValues(["platformVersion"]).then(ua => {
                    if (navigator.userAgentData.platform === "Windows") {
                        const majorPlatformVersion = parseInt(ua.platformVersion.split('.')[0]);
                        if (majorPlatformVersion >= 13) {
                            this.os.osVersion = 11;
                        }
                    }
                });
            }
            this.preSelect();
        },
        getIcon(filename){
            return getClassNameForFilename(filename);
        },
        bytesToSize(bytes) {
            return fileService.bytesToSize(bytes);
        }

    }
};
</script>
