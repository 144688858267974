<template>
    <div>

        
        <b-overlay :show="loading" opacity="0.92">
            <CCard style="width: 300px">
                <CCardBody @click="$bvModal.show('detail-faq-modal-' + data.id)" class="d-flex justify-content-center align-items-center flex-column click pt-4">
                    <p class="mt-3">
                        <img :src="data.image" v-show="picture" width="64" height="64" @load="picture = true" @error="picture = false">
                        <b-icon font-scale="4" icon="info-circle" v-show="!picture" variant="orange"></b-icon>
                    </p>
                    <div><strong>{{ data.question[locale] }}</strong></div>
                </CCardBody>
            </CCard>
            <template #overlay>
                <div class="text-center">
                    <span class="spinner-border"></span>
                    <p id="cancel-label">{{ loadingText }}</p>
                </div>
            </template>
        </b-overlay>



        <b-modal
            :id="'detail-faq-modal-' + data.id"
            size="xl"
            ok-only
            ok-variant="orange"
            :ok-title="$t('buttons.close')"
        >
            <template #modal-header="{ hide }">
                <div>
                    <h5 class="modal-title d-inline-block">FAQ: {{ data.question[locale] }}</h5>
                    <b-badge variant="orange" class="ml-2" v-if="data.users_faqs">{{ $t('pages.myResources.expiresAt') }}: {{ $moment(data.users_faqs.expiresAt).format('DD.MM.YYYY H:mm') }}</b-badge>
                </div>
                <button type="button" @click.prevent="hide" class="close">×</button>
            </template>
            <span v-html="data.answer[locale]"></span>

            <template #modal-footer="{ ok, cancel, hide }" class="d-flex justify-content-between">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button size="sm" variant="secondary" @click="hide('forget')">
                    {{ $t('buttons.close') }}
                </b-button>
                <div>

                </div>
            </template>
        </b-modal>
    </div>
  
</template>

<script>

import otherServices from "../../services/mainServices";
import mainServices from "../../services/mainServices";

export default {
    name: "tutorial-card",
    data: () => ({
        locale: 'de',
        loadingText: '',
        loading: false,
        picture: false
    }),
    props: ["data"],
    watch: {
        '$i18n.locale': function(newVal) {
            this.locale = newVal;
        }
    },
    mounted() {
        this.locale = mainServices.getLocale();
        this.loadingText = this.$t("pages.myResources.loadingMessage", {state: this.$t("states.start")})
    },
    methods: {
        openLink(){

        }
    }
};
</script>
