import { render, staticRenderFns } from "./pool-card.vue?vue&type=template&id=5899d37b&scoped=true"
import script from "./pool-card.vue?vue&type=script&lang=js"
export * from "./pool-card.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5899d37b",
  null
  
)

export default component.exports