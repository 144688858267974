import api from "./api";

export default {
    api: 'accounts/',
    /**
     * Get all resource account entries
     * @returns {Promise}
     */
    list(){
        return new Promise((resolve, reject) => {
            api().get(this.api).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Get a resource account entry
     * @param {string|number} id Resource Account ID
     * @returns {Promise}
     */
    get(id){
        return new Promise((resolve, reject) => {
            api().get(this.api + id).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Create new Resource Account and send the request to backend
     * @param {object} data Resource Account object with all attributes
     * @returns {Promise}
     */
    add(data) {
        return new Promise((resolve, reject) => {
            api().post(this.api, data).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Update Resource Account and send the request to backend
     * @param {object} data Resource Account object with all attributes, id must be included
     * @returns {Promise}
     */
    edit(data){
        return new Promise((resolve, reject) => {
            api().put(this.api + data.id, data).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Delete on or more Resource Account and send the request to backend
     * @param {array} data Array of resource account ids
     * @returns {Promise}
     */
    delete(data){
        return new Promise((resolve, reject) => {
            api().delete(this.api, { data: data}).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Get instance by id from a resource account
     * @param {string|number} id Instance ID
     * @returns {Promise}
     */
    getInstance(id){
        return new Promise((resolve, reject) => {
            api().get(this.api + id + '/instance').then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Get all instances from a resource account by id
     * @param {string|number} id Resource account id
     * @returns {Promise}
     */
    getInstances(id){
        return new Promise((resolve, reject) => {
            api().get(this.api + id + '/instances').then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Create new instance to a resource account and send the request to backend
     * @param {string|number} id Resource account id
     * @param {array} data Array of instance objects
     * @returns {Promise}
     */
    instanceAdd(id, data) {
        return new Promise((resolve, reject) => {
            api().post(this.api + `${id}/instances`, data).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Lock an instance by id
     * @param {string|number} instanceId Instance ID
     * @returns {Promise}
     */
    instanceLock(instanceId){
        return new Promise((resolve, reject) => {
            api().put(this.api + `instance/${instanceId}/lock`).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Reset an instance by id
     * @param {string|number} instanceId Instance ID
     * @returns {Promise}
     */
    instanceReset(instanceId){
        return new Promise((resolve, reject) => {
            api().put(this.api + `instance/${instanceId}/reset`).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Delete an instance by id
     * @param {string|number} instanceId Instance ID
     * @returns {Promise}
     */
    instanceDelete(instanceId){
        return new Promise((resolve, reject) => {
            api().delete(this.api + 'instance/' + instanceId).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },

};
