<template>
    <div>
        <card-outline 
            resourceType="app"
            :loading="loading"
            :loadingText="loadingText"
            :locale="locale"
        >
            <template #title>
                {{ name[locale] }}
            </template>

            <template #icon>
                <img :src="data.image" height="64" v-show="picture" @load="picture = true" @error="picture = false">
                <b-icon font-scale="4" icon="window" variant="orange" v-show="!picture"></b-icon>
            </template>

            <template #description>
                {{ description[locale] }}
            </template>

            <template #footer>
                <div>
                    <b-button size="sm mr-2" :title="$t('pages.myResources.buttons.downloadConnection')" variant="orange" @click="openLink">
                        <font-awesome-icon icon="play"/>
                        RDP Session
                    </b-button>
                </div>
            </template>
        </card-outline>
    </div>
</template>

<script>

import CardOutline from "./resource-card-outline.vue";
import rdpService from "../../services/rdpService";
import mainServices from "../../services/mainServices";

export default {
    name: "app-card",
    components: { CardOutline },
    props: ['data'],
    data: () => ({
        loading: false,
        loadingText: '',
        name: { de: '', en: ''},
        description: { de: '', en: ''},
        locale: 'de',
        picture: true
    }),
    mounted() {
        this.locale = mainServices.getLocale();
        this.loadingText = this.$t("pages.myResources.loadingMessage", {state: this.$t("states.start")});
        if (this.data.image === "") {
            this.picture = false;
        }
        this.name = JSON.parse(this.data.name)
        this.description = JSON.parse(this.data.description)
    },
    methods: {
        generateRDP() {
            let otherData = { userName: this.$store.getters["user/userName"] };
            let data = rdpService.generateFromTemplate(this.data.rdpTemplate, this.data.rdpTemplateVariables, otherData);
            mainServices.download(`${this.name[this.locale]}.rdp`, data);
        },
        openLink() {
            this.generateRDP();
        }
    }
};
</script>
