<template>
    <div>


        <b-overlay :show="loading" opacity="0.92">
            <CCard style="width: 300px">
                <CCardBody @click="openLink" class="d-flex justify-content-center align-items-center flex-column click">
                    <div class="d-flex justify-content-between width-100 mb-1">
                        <b-icon icon="info-circle" id="info-button" @click.prevent.stop="$bvModal.show('detail-rdsh-modal-' + data.id)"></b-icon>
                    </div>
                    <p>
                        <img :src="data.image" v-show="picture" width="64" height="64" @error="picture = false">
                        <b-icon font-scale="4" icon="display" variant="orange" v-show="!picture"></b-icon>
                    </p>
                    <div><strong>{{ name[locale] }}</strong></div>
                </CCardBody>
            </CCard>
            <template #overlay>
                <div class="text-center">
                    <span class="spinner-border"></span>
                    <p id="cancel-label">{{ loadingText }}</p>
                </div>
            </template>
        </b-overlay>



        <b-modal
            :id="'detail-rdsh-modal-' + data.id"
            size="md"
            ok-only
            ok-variant="orange"
            :ok-title="$t('buttons.close')"
        >
            <template #modal-header="{ hide }">
                <div>
                    <h5 class="modal-title d-inline-block">RDSH: {{ name[locale] }}</h5>
                    <b-badge variant="orange" class="ml-2" v-if="data.users_rdshs">{{ $t('pages.myResources.expiresAt') }}: {{ $moment(data.users_rdshs.expiresAt).format('DD.MM.YYYY H:mm') }}</b-badge>
                </div>
                <button type="button" @click.prevent="hide" class="close">×</button>
            </template>
            {{ description[locale] }}

            <template #modal-footer="{ ok, cancel, hide }" class="d-flex justify-content-between">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button size="sm" variant="secondary" @click="hide('forget')">
                    {{ $t('buttons.close') }}
                </b-button>
                <div>
                    <b-button size="sm" :title="$t('pages.myResources.buttons.downloadConnection')" variant="orange" @click="generateRDP">
                        <font-awesome-icon icon="download"/>
                        RDP Session
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>

import rdpService from "../../services/rdpService";
import mainServices from "../../services/mainServices";

export default {
    name: "rdsh-card",
    props: ['data'],
    data: () => ({
        loading: false,
        loadingText: '',
        name: {
            de: '',
            en: ''
        },
        description: {
            de: '',
            en: ''
        },
        locale: 'de',
        picture: true
    }),
    watch: {
        '$i18n.locale': function (newVal) {
            this.locale = newVal;
        }
    },
    mounted() {
        this.loadingText = this.$t("pages.myResources.loadingMessage", {state: this.$t("states.start")});
        if (this.data.image === "") {
            this.picture = false;
        }
        this.name = JSON.parse(this.data.name)
        this.description = JSON.parse(this.data.description)
        this.locale = mainServices.getLocale();
    },
    methods: {
        generateRDP() {
            let otherData = {userName: this.$store.getters["user/userName"]};
            let data = rdpService.generateFromTemplate(this.data.rdpTemplate, this.data.rdpTemplateVariables, otherData);
            mainServices.download(`${this.name[this.locale]}.rdp`, data);
        },
        openLink() {
            this.generateRDP();
        }
    }
};
</script>
