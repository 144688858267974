import icons from './icons'

const extensions = {
    gif: icons.image,
    jpeg: icons.image,
    jpg: icons.image,
    png: icons.image,

    pdf: icons.pdf,

    doc: icons.word,
    docx: icons.word,

    ppt: icons.powerpoint,
    pptx: icons.powerpoint,

    xls: icons.excel,
    xlsx: icons.excel,

    csv: icons.csv,

    aac: icons.audio,
    mp3: icons.audio,
    ogg: icons.audio,

    avi: icons.video,
    flv: icons.video,
    mkv: icons.video,
    mp4: icons.video,

    gz: icons.archive,
    zip: icons.archive,

    css: icons.code,
    html: icons.code,
    js: icons.code,

    txt: icons.text
}

export default extensions
