<template>
    <b-col xs="6" sm="6" md="4" lg="3" xl="2">
        <CCard class="vdi-card">
            <b-skeleton-wrapper :loading="loading">
                <template #loading>
                    <CCardBody class="d-flex justify-content-center align-items-center flex-column" v-if="type === undefined || type === 'vdi'">
                        <div class="d-flex justify-content-between mb-2 width-100">
                            <b-skeleton type="avatar" size="17px"></b-skeleton>
                            <div class="d-flex justify-content-end">
                                <b-skeleton width="70px" class="mr-1"></b-skeleton>
                                <b-skeleton width="60px"></b-skeleton>
                            </div>
                        </div>
                        <p class="mb-4">
                            <b-skeleton type="avatar" size="64px"></b-skeleton>
                        </p>
                        <div class="width-100 text-center d-flex justify-content-center flex-column align-items-center">
                            <b-skeleton width="55%"></b-skeleton>
                            <b-skeleton width="70%" class="mt-1"></b-skeleton>
                            <b-skeleton width="85%" class="mt-1"></b-skeleton>
                        </div>
                    </CCardBody>
                    <CCardBody class="d-flex justify-content-center align-items-center flex-column" v-if="type === 'app' || type === 'link'">
                        <div class="d-flex justify-content-between mb-2 width-100">
                            <b-skeleton type="avatar" size="17px"></b-skeleton>
                        </div>
                        <p class="mb-3">
                            <b-skeleton-icon
                                :icon="type === 'app' ? 'window' : 'link-45deg'"
                                :icon-props="{ fontScale: 4 }"
                            ></b-skeleton-icon>
                        </p>
                        <div class="width-100 text-center d-flex justify-content-center flex-column align-items-center">
                            <b-skeleton width="85%"></b-skeleton>
                        </div>
                    </CCardBody>
                </template>
                <CCardBody>
                    <span>{{ $t('generally.noEntries') }}</span>
                </CCardBody>
            </b-skeleton-wrapper>
        </CCard>
    </b-col>
</template>

<script>

export default {
    name: "skeleton-card",
    props: ['loading', 'type'],
    data: () => ({})
};
</script>
