<template>
    <div>
        <b-overlay :show="loading" opacity="0.92" >
            <CCard style="width: 480px">
                <CCardHeader class="d-flex justify-content-between">
                    <div>
                        <b-icon icon="grid3x3-gap-fill" /> {{ $t('generally.resource.pool') }} <small class="text-muted">({{ $t('pages.vdi.pools.values.type.' + data.type) }})</small>
                    </div>
                   
                   
                     <div>
                        <small class="text-muted">Status:</small>
                        <b-badge :variant="isConnectable ? 'success' : 'warning'" class='mx-1'>{{ data.status && data.status.freeVmsCount ? data.status.freeVmsCount : '0' }}</b-badge>
                        <b-badge>{{ updateCount }}</b-badge>
                    </div>
                    
                </CCardHeader>
                <CCardBody  class="d-flex flex-column click">
                    <CCardTitle>{{ data.publicName[locale] }}</CCardTitle> 
                    <b-row  class="text-black-50 width-100 mt-1 ml-1">
                        <b-col v-if="data.status && data.status.refVm" cols="5" class="m-0 p-0">
                            <div class="d-flex align-content-start">
                                <font-awesome-icon :class="getColor()" :icon="getOs()" size="3x"/>
                                <div class="d-flex align-items-start flex-column ml-3">
                                    <div><b-icon icon="cpu-fill" class="mr-2 align-self-start">"></b-icon> {{ data.status.refVm.cpu.count }} {{ $t('pages.myResources.cores') }}</div>
                                    <div><font-awesome-icon icon="memory" class="mr-2"/> {{ data.status.refVm.memory.size_MiB / 1024 }} GiB</div>
                                </div>
                            </div>
                            <div><small>{{ translateOS(data.status.refVm.guest_OS) }}</small></div>
                        
                        </b-col>
                        <b-col v-else cols="5" class="m-0 p-0">
                            <b-skeleton width="85%"></b-skeleton>
                            <b-skeleton width="35%" class="mt-3"></b-skeleton>
                            <b-skeleton width="50%" class="mt-1"></b-skeleton>
                            <b-skeleton width="75%" class="mt-1"></b-skeleton>
                        </b-col>
                        <b-col cols="7" class="m-0 p-0">
                            {{ data.description[locale] }}
                        </b-col>
                    </b-row>

                </CCardBody>

                <CCardFooter v-if="isConnectable" class="d-flex justify-content-between">
                     <div>
                        <b-button size="sm mr-2" :title="$t('pages.myResources.buttons.downloadConnection')" variant="orange" @click="webConsoleConnect">
                            <font-awesome-icon icon="play"/>
                            Web Session
                        </b-button>
                        <b-button size="sm" :title="$t('pages.myResources.buttons.downloadConnection')" variant="orange" @click="generateRDP">
                            <font-awesome-icon icon="play"/>
                            RDP Session
                        </b-button>
                    </div>
                    <div v-if="hasActivSession">
                        <a href="#" :title="$t('buttons.start')" class="mx-1" v-show="data.status.refVm.power_state !== 'POWERED_ON'" @click.prevent="sendAction('start')">
                            <font-awesome-icon class="text-success" icon="play"/>
                        </a>
                        <a href="#" :title="$t('buttons.reboot')" class="mx-1" v-show="data.status.refVm.power_state === 'POWERED_ON'" @click.prevent="sendAction('reboot')">
                            <font-awesome-icon class="text-info" icon="undo"/>
                        </a>
                        <a href="#" :title="$t('buttons.shutdown')" class="mx-1" v-show="data.status.refVm.power_state === 'POWERED_ON'" @click.prevent="sendAction('shutdown')">
                            <font-awesome-icon class="text-purple" icon="power-off"/>
                        </a>
                    </div>
                </CCardFooter>
                
            </CCard>
            <template #overlay>
                <div class="text-center">
                    <span class="spinner-border"></span>
                    <p id="cancel-label">{{ loadingText }}</p>
                </div>
            </template>
        </b-overlay>



        <!-- Infomodal -->
        <b-modal
            :id="'detail-pool-modal-' + data.id"
            size="lg"
            ok-only
            ok-variant="orange"
            :ok-title="$t('buttons.close')"
            :title="'Pool: ' + data.name"
        >
            <template #modal-header="{ hide }">
                <div>
                    <h5 class="modal-title d-inline-block">Pool: {{ data.name }}</h5>
                    <b-badge variant="orange" class="ml-2" v-if="data.users_pools">{{ $t('pages.myResources.expiresAt') }}: {{ $moment(data.users_pools.expiresAt).format('DD.MM.YYYY H:mm') }}</b-badge>
                </div>
                <button type="button" @click.prevent="hide" class="close">×</button>
            </template>
            <!--<pool-details :name="data.name" :data="data" :session="sessionData"></pool-details>-->

            <template #modal-footer="{ hide }" class="d-flex justify-content-between">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button size="sm" variant="orange" @click="hide('forget')">
                    {{ $t('buttons.close') }}
                </b-button>
                
                <div v-if="isConnectable">
                    <b-button size="sm" :title="$t('pages.myResources.buttons.downloadConnection')" variant="orange" @click="generateRDP">
                        <font-awesome-icon icon="download"/>
                        RDP Session
                    </b-button>
                    <b-button-group v-if="hasActivSession" size="sm" class="ml-1">
                        <b-button variant="outline-secondary" :title="$t('buttons.start')" v-if="status.refVm.power_state !== 'POWERED_ON'" @click.prevent="sendAction('start')">
                            <font-awesome-icon class="text-success" icon="play"/>
                        </b-button>
                        <b-button variant="outline-secondary" :title="$t('buttons.reset')" v-if="status.refVm.power_state === 'POWERED_ON'" @click.prevent="sendAction('reset')">
                            <font-awesome-icon class="text-warning" icon="redo"/>
                        </b-button>
                        <b-button variant="outline-secondary" :title="$t('buttons.hardStop')" v-if="status.refVm.power_state === 'POWERED_ON'" @click.prevent="sendAction('stop')">
                            <font-awesome-icon class="text-danger" icon="stop"/>
                        </b-button>
                        <b-button variant="outline-secondary" :title="$t('buttons.reboot')" v-if="status.refVm.power_state === 'POWERED_ON'" @click.prevent="sendAction('reboot')">
                            <font-awesome-icon class="text-info" icon="undo"/>
                        </b-button>
                        <b-button variant="outline-secondary" :title="$t('buttons.shutdown')" v-if="status.refVm.power_state === 'POWERED_ON'" @click.prevent="sendAction('shutdown')">
                            <font-awesome-icon class="text-purple" icon="power-off"/>
                        </b-button>
                    </b-button-group>
                </div>
            </template>
        </b-modal>


    </div>
</template>

<script>
import poolService from "../../services/poolService";
import userService from "../../services/userService";
import vmService from "../../services/vmService";
import rdpService from "../../services/rdpService";
import mainServices from "../../services/mainServices";
import consoleService from "../../services/consoleService";
import PoolDetails from "./poolDetails";

export default {
    name: "pool-card",
    components: { PoolDetails },
    props: {
        resourceId: { type: String },
        initData: { type: Object }
    },
    watch: {
        '$i18n.locale': function (newVal) {
            this.locale = newVal;
        },
        initData: function(newVal) {
            this.setData(newVal);
        }
    },
    data: () => ({
        data: { publicName: {"de":"", "en":""}, description: {"de":"", "en":""} },
        loading: false,
        os: 'unknown',
        info: false,
        updater: false,
        updateCount: 1,
        rdpFile: '',
        loadingText: '',
        locale: '',
    }),
    mounted() {
        console.log(this.initData);
        this.setData(this.initData);
        this.startUpdater();
        this.locale = mainServices.getLocale();
    },
    computed: {
        isConnectable() {
            if(this.data.status) {
                switch (this.data.status.type) {
                    case 'activ-session':     
                    case 'static-assigned':
                        return true;
                    case 'static-unassigned':
                    case 'dynamic-unassigned':
                        return this.data.status.freeVmsCount > 0;
                    default:
                        return false;
                }
            }
            return false;
        },
        hasActivSession() {
            if(this.data.status) {
                switch (this.data.status.type) {
                    case 'activ-session':     
                    case 'static-assigned':
                        return true;
                    default:
                        return false;
                }
            }
            return false;
        }
    },
    methods: {
        getLocale() {
            return localStorage.getItem("locale");
        },
        setData(data) {
            if(data && (JSON.stringify(data) != JSON.stringify(this.data))) {
                this.data = data;
                this.data.publicName = this.data.publicName ? JSON.parse(this.data.publicName) : {"de":"", "en":""};
                this.data.description = this.data.description ? JSON.parse(this.data.description) : {"de":"", "en":""};
            }
        },
        startSession() {
            this.loading = true;
            this.loadingText = this.$t("pages.myResources.loadingMessage", {state: this.$t("states.start")});
            
            poolService.connect(this.data.id)
            .then(response => {
                this.loading = false;
                const connectionData = {
                    host: response.data.network.ipPreferred,
                    port: response.data.config.vncPort,
                    password: response.data.credentials.vncPassword
                };
                this.openVNC(connectionData);
            }).catch(error => {
                this.loading = false;
                this.errorHandler(error, [
                    {
                        status: 500,
                        message: this.$t('pages.myResources.messages.pools.error.500.message'),
                        title: this.$t('pages.myResources.messages.pools.error.500.title'),
                    }
                ]);
            });
           
        },
        generateRDP() {
            let host = '';
            
            //if (this.sessionData.connectionData) {
            //    host = this.sessionData.connectionData.host;
            //}
            let otherData = {
                userName: this.$store.getters["user/userName"],
                localUserName: "Administrator",
                //localPassword: this.sessionData.connectionData.localPassword,
                host: host
            };
            let data = rdpService.generateFromTemplate(this.data.rdpTemplate, this.data.rdpTemplateVariables, otherData);
            mainServices.download(this.data.name + ".rdp", data);
        },
        translateOS(os) {
            return vmService.translateOS(os);
        },
        evaluateOs() {
            let guest_OS = (this.data.status && this.data.status.refVm) ? this.data.status.refVm.guest_OS : undefined;
            if (guest_OS === undefined) guest_OS = "";
            let os = "undefined";
            if (guest_OS.match(/windows/i)) {
                os = "windows";
            } else if (guest_OS.match(/ubuntu/i)) {
                os = "ubuntu";
            } else if (guest_OS.match(/suse/i)) {
                os = "suse";
            } else if (guest_OS.match(/centos/i)) {
                os = "centos";
            } else if (guest_OS.match(/redhat/i)) {
                os = "redhat";
            } else if (guest_OS.match(/linux/i)) {
                os = "linux";
            }
            return os;
        },
        getOs() {
            let type = "";
            switch (this.evaluateOs()) {
                case 'windows':
                    type = ['fab', 'windows'];
                    break;
                case 'ubuntu':
                    type = ['fab', 'ubuntu'];
                    break;
                case 'suse':
                    type = ['fab', 'suse'];
                    break;
                case 'centos':
                    type = ['fab', 'centos'];
                    break;
                case 'redhat':
                    type = ['fab', 'redhat'];
                    break;
                case 'linux':
                    type = ['fab', 'linux'];
                    break;
                default:
                    type = ['far', 'question-circle'];
            }
            return type;
        },
        getColor() {
            let color = "";
            switch (this.evaluateOs()) {
                case 'windows':
                    color = 'text-cyan';
                    break;
                case 'ubuntu':
                    color = 'text-orange';
                    break;
                case 'suse':
                    color = 'text-success';
                    break;
                case 'redhat':
                    color = 'text-danger';
                    break;
                case 'linux':
                    color = 'text-dark';
                    break;
                default:
                    color = 'text-dark';
            }
            return color;
        },

        webConsoleConnect() {
            this.loading = true;
            this.loadingText = this.$t("pages.myResources.loadingMessage", {state: this.$t("states.start")});
           
            poolService.connect(this.data.id).then(response => {
                this.loading = false;
                const connectionData = {
                    host: response.data.network.ipPreferred,
                    port: response.data.config.vncPort,
                    password: response.data.credentials.vncPassword
                };
                this.openNoVncPage(connectionData);
            }).catch(error => {
                this.loading = false;
                this.errorHandler(error, [
                    {
                        status: 500,
                        message: this.$t('pages.myResources.messages.pools.error.500.message'),
                        title: this.$t('pages.myResources.messages.pools.error.500.title'),
                    }
                ]);
            });
        },
        openNoVncPage(connectionData) {
            const tab = window.open('about:blank', '_self');
            tab.document.write(consoleService.getNoVncPage(connectionData));
            tab.document.close();
        },
        startUpdater() {
            this.updateResource();
            this.stopUpdater();
            this.updateCount = 10;
            this.updater = setInterval(this.updateResource, 8000);
        },
        stopUpdater() {
            if (this.updater != null) {
                clearInterval(this.updater);
                this.updater = null;
            }
        },
        updateResource() {
            userService.getResource(this.$store.getters["user/id"], this.resourceId)
            .then(response => {
                if(JSON.stringify(this.data) != JSON.stringify(response.data)) {
                    this.setData(response.data);
                }
                this.updateCount -= 1;
                if(this.updateCount < 1) {
                    this.stopUpdater();
                }
            }).catch(() => {
                this.stopUpdater();
            });
        },
        sendAction(action) {
            if (!this.loading) {
                this.loading = true;
                this.loadingText = this.$t("pages.myResources.loadingMessage", {state: this.$t("states." + action)});
                vmService.sendPowerAction(this.vmRef, action).then(() => {
                    this.$toastr.s("Erfolg!");
                    this.loading = false;
                    this.updateVmInfo();
                }).catch(error => {
                    this.loading = false;
                    this.errorHandler(error);
                });
            }
        }
    },
    beforeDestroy() {
        if (this.updater != null) {
            clearInterval(this.updater);
            this.updater = null;
        }
    }
};
</script>

<style scoped>
</style>