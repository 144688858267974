<template>
    <account-card v-if="resourceType === 'account'" :data="data" />
    <app-card v-else-if="resourceType === 'app'" :data="data" />
    <download-card v-else-if="resourceType === 'download'" :data="data" />
    <link-card v-else-if="resourceType === 'link'" :data="data" />
    <rdsh-card v-else-if="resourceType === 'rdsh'" :data="data" />
    <faq-card v-else-if="resourceType === 'faq'" :data="data" />
    <pool-card v-else-if="resourceType === 'pool'" :resourceId="resourceId" :initData="data" />
    <skeleton-card v-else-if="resourceType === 'unknown'" :data="data" />
</template>

<script>

import AccountCard from "./account-card";
import AppCard from "./app-card";
import DownloadCard from "./download-card";
import LinkCard from "./link-card";
import RdshCard from "./rdsh-card";
import FaqCard from "./faq-card";
import PoolCard from "./pool-card";
import SkeletonCard from "./skeleton-card.vue"


export default {
    name: "resource-card",
    components: {
        AccountCard,
        AppCard,
        DownloadCard, 
        LinkCard,
        RdshCard,
        FaqCard, 
        PoolCard,
        SkeletonCard
    },
     props: {
        resourceId: { type: String, default: 'unknown-'},
        data: { type: Object, default: () => { return { resourceId: ''} } }
    },
    computed: {
        resourceType() {
            const splitedResourceId = this.resourceId.split("-", 1);
            return splitedResourceId[0] ? splitedResourceId[0] : false;
        }
    }
};
</script>
