export default {
    /**
     * Generate RDP file from template
     * @param {string} template
     * @param {object} mappingTable
     * @param {object} data
     * @returns {string}
     */
    generateFromTemplate(template, mappingTable, data){
        for(let item of mappingTable){
            const regex = /^.*(?<place>{(?<varname>[a-zA-Z0-9]+)}).*$/;
            const match = regex.exec(item.value);
            if (match && match.groups && match.groups.place && match.groups.varname) {
                item.value = item.value.replace(match.groups.place, data[match.groups.varname])
            }
            let re = new RegExp('{{' + item.name + '}}', "gm")
            template = template.replaceAll(re, item.value)
        }
        return template;
    }
}
