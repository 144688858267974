<template>
    <div>
        <card-outline 
            resourceType="account"
            :loading="loading"
            :loadingText="loadingText"
            :locale="locale"
        >
            <template #title>
                {{ name[locale] }}
            </template>

            <template #icon>
                <img :src="data.image" height="64" v-show="picture" @load="picture = true" @error="picture = false">
                <font-awesome-icon icon="user-lock" size="4x" v-show="!picture" class="text-orange"/>
            </template>

            <template #description>
                {{ description[locale] }}
            </template>

            <template #footer>
                <div>
                    <b-button size="sm mr-2" :title="$t('pages.myResources.buttons.getAccountInstance')" variant="orange" @click="getInstance">
                        <font-awesome-icon icon="play"/>
                        {{ $t('pages.myResources.buttons.getAccountInstance') }}
                    </b-button>
                </div>
            </template>

        </card-outline>


        <b-modal
            :id="'detail-account-modal-' + data.id"
            size="lg"
            ok-only
            ok-variant="orange"
            :ok-title="$t('buttons.close')"
        >
            <template #modal-header="{ hide }">
                <div>
                    <h5 class="modal-title d-inline-block">{{ name[locale] }}</h5>
                    <b-badge variant="orange" class="ml-2" v-if="data.users_downloads">{{ $t('pages.myResources.expiresAt') }}: {{ $moment(data.users_downloads.expiresAt).format('DD.MM.YYYY H:mm') }}</b-badge>
                </div>
                <button type="button" @click.prevent="hide" class="close">×</button>
            </template>
            <p>
                <span v-html="instanceInfo"></span>
            </p>
            <b-table-simple v-if="account !== null">
                <b-tbody>
                    <b-tr>
                        <b-td>{{ $t('pages.myResources.resource.accounts.name') }}</b-td>
                        <b-td>{{ account.name }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ $t('pages.myResources.resource.accounts.secret') }}</b-td>
                        <b-td>{{ account.secret }}</b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <template #modal-footer="{ ok, cancel, hide }" class="d-flex justify-content-between">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button size="sm" variant="secondary" @click="hide('forget')">
                    {{ $t('buttons.close') }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import CardOutline from "./resource-card-outline.vue";
import mainServices from "../../services/mainServices";
import accountService from "../../services/accountService";

export default {
    name: "account-card",
    components: { CardOutline },
    props: ['data'],
    data: () => ({
        loading: false,
        locale: 'de',
        loadingText: '',
        name: {de: '', en: ''},
        description: { de: '', en: ''},
        picture: false,
        account: null,
        instanceInfo: ''
    }),
    watch: {
        '$i18n.locale': function(newVal) {
            this.locale = newVal;
        }
    },
    mounted() {
        this.updateLocale();
        this.parse();
        this.loadingText = this.$t("pages.myResources.loadingAccount")
    },
    methods: {
        updateLocale(){
            this.locale = mainServices.getLocale();
        },
        parse(){
            this.name = JSON.parse(this.data.name);
            this.description = JSON.parse(this.data.description);
        },
        getInstance(){
            if(!this.loading) {
                this.loading = true;
                accountService.getInstance(this.data.id).then(response => {
                    this.account = response.data;
                    this.account.infoValues = JSON.parse(this.account.infoValues);
                    this.loading = false;
                    this.$bvModal.show('detail-account-modal-' + this.data.id)
                    this.parseInstanceInfo();
                }).catch(err => {
                    this.errorHandler(err, [{
                        status: 404,
                        message: this.$t('pages.myResources.messages.accounts.error.404')
                    }]);
                    this.loading = false;
                });
            }
        },
        parseInstanceInfo(){
            let tpl = this.data.instanceInfoTemplate;
            tpl = tpl.replaceAll(/\{\{([a-zA-Z0-9.]+)\}\}/mg, (reg, path) => {
                return mainServices.getAttrFromString(this.account.infoValues, path);
            })
            this.instanceInfo = tpl;
        }
    }
};
</script>
