<template>
    <div>


        
        <b-overlay :show="loading" opacity="0.92">
            <CCard style="width: 300px">
                <CCardBody @click="openLink" class="d-flex justify-content-center align-items-center flex-column click">
                    <div class="d-flex justify-content-between width-100 mb-1">
                        <b-icon icon="info-circle" id="info-button" @click.prevent.stop="$bvModal.show('detail-link-modal-' + data.id)"></b-icon>
                    </div>
                    <p>
                        <img :src="data.image" v-show="picture" width="64" height="64" @load="picture = true" @error="picture = false">
                        <b-icon-link45deg font-scale="4" variant="orange" v-show="!picture"></b-icon-link45deg>
                    </p>
                    <div><strong>{{ JSON.parse(data.name)[locale] }}</strong></div>
                </CCardBody>
            </CCard>
            <template #overlay>
                <div class="text-center">
                    <span class="spinner-border"></span>
                    <p id="cancel-label">{{ loadingText }}</p>
                </div>
            </template>
        </b-overlay>



        <b-modal
            :id="'detail-link-modal-' + data.id"
            size="md"
            ok-only
            ok-variant="orange"
            :ok-title="$t('buttons.close')"
        >
            <template #modal-header="{ hide }">
                <div>
                    <h5 class="modal-title d-inline-block">Link: {{ JSON.parse(data.name)[locale] }}</h5>
                    <b-badge variant="orange" class="ml-2" v-if="data.users_links">{{ $t('pages.myResources.expiresAt') }}: {{ $moment(data.users_links.expiresAt).format('DD.MM.YYYY H:mm') }}</b-badge>
                </div>
                <button type="button" @click.prevent="hide" class="close">×</button>
            </template>
            {{ JSON.parse(data.description)[locale] }}

            <template #modal-footer="{ ok, cancel, hide }" class="d-flex justify-content-between">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button size="sm" variant="secondary" @click="hide('forget')">
                    {{ $t('buttons.close') }}
                </b-button>
                <div>
                    <b-button size="sm" :title="$t('buttons.open')" variant="orange" @click="openLink">
                        <font-awesome-icon icon="link"/>
                        {{ $t('buttons.open') }}
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>

import otherServices from "../../services/mainServices";
import mainServices from "../../services/mainServices";

export default {
    name: "link-card",
    props: ['data'],
    data: () => ({
        loading: false,
        info: false,
        update: null,
        loadingText: '',
        locale: 'de',
        picture: false
    }),
    watch: {
        '$i18n.locale': function(newVal) {
            this.locale = newVal;
        }
    },
    mounted() {
        this.loadingText = this.$t("pages.myResources.loadingMessage", {state: this.$t("states.start")})
        this.locale = mainServices.getLocale();
    },
    methods: {
        openLink(){
            otherServices.openLink(this.data.url);
        }
    }
};
</script>
