import api from "./api";

export default {
    api: 'downloads',
    /**
     * Get all downloads
     * @returns {Promise}
     */
    list(){
        return new Promise((resolve, reject) => {
            api().get(this.api).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Get a download resource by id
     * @param {string|number} id download id
     * @returns {Promise}
     */
    get(id){
        return new Promise((resolve, reject) => {
            api().get(this.api + '/' + id).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Get download code for a file
     * @param {string|number} id Download Resource ID
     * @param {string} hash File Hash of the desired file
     * @returns {Promise}
     */
    getDownload(id, hash){
        return new Promise((resolve, reject) => {
            api().get(this.api + '/' + id + '/' + hash).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Create new Download resource and send the request with files to backend
     * @param {object} data Download object with all attributes and files to upload
     * @param {function} onUploadProgress Function which called by update upload process, the process event is passed as the first param
     * @returns {Promise}
     */
    add(data, onUploadProgress) {
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            let files = data.files;
            // Add data without files to request body
            formData.append("name", JSON.stringify(data.name));
            formData.append("description", JSON.stringify(data.description));
            formData.append("public", data.public);
            formData.append("image", data.image);
            formData.append("type", data.type);
            let labels = [];

            // prepare file array
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                labels.push({
                    file: file.name,
                    label: file.label
                })
                // append file to body
                formData.append('files[' + i + ']', file);
            }
            // append all file labels to body
            formData.append("labels", JSON.stringify(labels));

            // send request to backend
            api().post(this.api, formData,
                {
                    headers: {'Content-Type': 'multipart/form-data'},
                    onUploadProgress
                }
            ).then((response) => {
                resolve(response);
            }).catch((response) => {
                reject(response);
            });
        });
    },
    /**
     * Update download resource by id
     * @param {object} data Download object with all attributes and files to upload
     * @param {function} onUploadProgress Function which called by update upload process, the process event is passed as the first param
     * @returns {Promise}
     */
    edit(data, onUploadProgress){
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            let files = data.files;
            // Add data without files to request body
            formData.append("name", JSON.stringify(data.name));
            formData.append("description", JSON.stringify(data.description));
            formData.append("public", data.public);
            formData.append("type", data.type);
            formData.append("image", data.image);
            formData.append("deleteFiles", JSON.stringify(data.files.filter(file => file.state === 'delete').map(file => (file.name))));
            let labels = [];

            // prepare files
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                // add only labels from new, updated or unchanged files
                if(file.state !== 'delete') {
                    labels.push({
                        file: file.name,
                        label: file.label
                    })
                }
                // upload only new files
                if(file.state === 'new') {
                    formData.append('files[' + i + ']', file);
                }
            }
            // add file labels to body
            formData.append("labels", JSON.stringify(labels));

            api().put(this.api + "/" + data.id, formData,
                {
                    headers: {'Content-Type': 'multipart/form-data'},
                    onUploadProgress
                }
            ).then((response) => {
                resolve(response);
            }).catch((response) => {
                reject(response);
            });
        });
    },
    /**
     * Delete one or more download resources
     * @param {array} data Array of download resource ids to delete
     * @returns {Promise}
     */
    delete(data){
        return new Promise((resolve, reject) => {
            api().delete(this.api, { data: data}).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
};
