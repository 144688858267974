const icons = {
    image: 'file-image',
    pdf: 'file-pdf',
    word: 'file-word',
    powerpoint: 'file-powerpoint',
    excel: 'file-excel',
    csv: 'file-csv',
    audio: 'file-audio',
    video: 'file-video',
    archive: 'file-archive',
    code: 'file-code',
    text: 'file-alt',
    file: 'file'
}

export default icons
